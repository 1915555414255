<script setup lang="ts">
  const props = defineProps<{
    source?: string
    name?: string
    active?: boolean
    hoverable?: boolean
  }>()

  const nameAbbreviation = computed<string | undefined>(() => {
    if (!props.name) {
      return undefined
    }

    const nameParts = props.name.trim().split(' ')

    if (nameParts.length >= 1) {
      const abbFirst = nameParts[0]?.charAt(0) || ''
      const abbLast =
        nameParts.length > 1 ? nameParts[nameParts.length - 1]?.charAt(0) : ''
      return `${abbFirst}${abbLast}`.toUpperCase()
    }

    return undefined
  })
  const fullName = computed<string>(() => props.name ?? '')
</script>

<template>
  <div
    class="flex aspect-1 shrink-0 items-center justify-center overflow-hidden"
    :class="{
      'bg-dark-200 text-dark-400 dark:bg-dark-400 dark:text-dark-500': !active,
      'bg-primary-200 text-primary-400 dark:bg-primary-300 dark:text-primary-500':
        active,
      'hover:bg-primary-200 hover:text-primary-500 dark:hover:bg-primary-200 dark:hover:text-primary-500':
        hoverable,
    }"
  >
    <img
      v-if="source"
      :src="source"
      :alt="fullName"
      class="m-0 max-h-full max-w-full rounded-md object-cover"
    />

    <div
      v-else-if="nameAbbreviation"
      :title="fullName"
      class="mt-px font-semibold leading-none"
    >
      {{ nameAbbreviation }}
    </div>

    <SvgImage
      v-else
      symbol="icon-user-solid"
      class="p-1"
      :title="fullName"
    />
  </div>
</template>
